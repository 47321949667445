import React from "react"
import { Meta, Title } from "react-head"
import MainLayout from "../components/MainLayout"
import IsaContent from "../components/Isa-content/IsaContent"

const ChancenIsa = () => {
  return (
    <MainLayout>
      <Title>Codetrain Africa |Chancen-Isa</Title>
      <Meta
        name="description"
        content="Scholarships at Codetrain are given for three reasons."
      />

      <IsaContent />
    </MainLayout>
  )
}

export default ChancenIsa
