import React from "react"

const IsaContent = () => {
  return (
    <div className="section-container course-and-careers-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10 col-md-12 ">
            <div className="main-section-heading text-center">
              <h3>Codetrain Africa Study Now Pay Later</h3>
            </div>
            <p className="mb-5 ">
              Codetrain is excited to announce its partnership with Chancen
              International, introducing an innovative financial model for
              students. This model empowers individuals without immediate
              financial resources to pursue their studies now and pay later. The
              initiative kicks off with a pilot program, initially supporting 20
              students on their educational journey.
            </p>
            <div className="row">
              <div className="col-md-12">
                <h5>About Chancen International</h5>
                <p>
                  CHANCEN International is a not-for-profit organization that
                  provides an ethical, fair, and responsible financing model for
                  young people in Ghana and in Africa to access quality tertiary
                  education. This model of financing is called an Income Share
                  Agreement (ISA).
                </p>
              </div>
              <div className="col-md-12 " style={{ marginTop: 20 }}>
                <h5>What is Income Share Agreement?</h5>
                <p>
                  An Income Share Agreement (ISA) is a fair and ethical way for
                  you to finance your education without creating any burdensome
                  debt. You get the freedom to focus on your studies without any
                  pressure and enter the world of work focusing things that
                  really matter.
                </p>
              </div>{" "}
              <div className="col-md-12 " style={{ marginTop: 20 }}>
                <h5>Benefits of Income share Agreement (ISA)</h5>
                <ul>
                  <li> You study now and pay later.</li>
                  <li> You start paying when you start making an income.</li>
                  <li>
                    You only pay a certain percentage of your net income during
                    repayment.
                  </li>
                  <li>
                    Your repayment is capped so that you are not saddled with
                    debt.
                  </li>
                  <li>
                    Your repayments go towards the fund for the next generation.
                  </li>
                </ul>
              </div>
              <div className="col-md-12 " style={{ marginTop: 10 }}>
                <h5>Requirements for applicants</h5>
                <ul>
                  <li> Ghana Card</li>
                  <li>Recent Passport Photos (no more than 6 months).</li>
                  <li>Age between 18-35.</li>

                  <li>
                    Evidence of loans from Credit Bureau/SSNIT. Must be in good
                    standing.
                  </li>
                  <li>
                    Must not be enrolled in a National Service program for the
                    duration of the Course.
                  </li>
                  <li>
                    You must have 2 guardians (1 Family member, 1
                    Relative/friend).
                  </li>
                  <li>Proof of address.</li>
                </ul>
              </div>
              <div className="mt-4">
                <p>
                  Application ends on the 12th of March 2024. Class starts
                  April/May.
                </p>
              </div>
              <div className="col-md-12 " style={{ marginTop: 20 }}>
                <h5>Contact.</h5>
                <p>For any enquiry/information</p>
                <p>Call 0545792397</p>
              </div>
              {/* <div className="col-md-12 " style={{ marginTop: 10 }}>
                <h5>Important Notice</h5>
                <p>
                  Applying does not guarantee you that Chancen will select you.
                  All applicants must go through a selection process.
                </p>
              </div> */}
            </div>

            <br />

            <div className="row">
              <a
                href="https://tally.so/r/wkNerd"
                className="btn-custom-primary-large"
              >
                Apply to Study Now, Pay Later
              </a>

              {/* <div className="col-md-6 col-xs-12 text-center mx-auto">
                <p className="fees-btn">
                  <Link
                    to="/scholarship"
                    className="btn-outline-custom-secondary-normal"
                  >
                    Apply for a scholarship
                  </Link>
                </p>
              </div> */}
            </div>
          </div>
          <div className="col-lg-1 col-md-0  mt-4">
            {/* <a
              href="https://tally.so/r/wkNerd"
              className="btn-custom-primary-large  "
            >
              Apply Now
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default IsaContent
